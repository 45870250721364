var mobileBreakpoint = 1023;
var tabletBreakpoint = 1023;
var screenVw = jQuery(window).width();
var screenVh = jQuery(window).height();
var screenHalf = screenVh + 600;
var lazyloadOptions = {
  elements_selector: '.lazy, .image-bg-lazy',
  threshold: 300,
  class_loading: 'lazyload-loading',
  class_loaded: 'lazyload-loaded'
};
var lazyLoad;

whatInput = whatInput.ask(); // returns `mouse`, `keyboard` or `touch`

jQuery(document).ready(function($){

  var vHeight = window.innerHeight;



  jQuery('.menuPlus').keypress(function(e) { 
    loadNav();
  });
  jQuery('.menuPlus').click(function(e) { 
    loadNav();
  });


  function loadPost() {
      jQuery('a.news-link').click(function(e) { 
        e.preventDefault();

        var postID = jQuery(this).attr('rel');
        var post = site.root + '/wp-json/wp/v2/posts';
        var postURL = post + '/' + postID + '?_embed';
        jQuery('.news-preview h2').html('<span>In the news</span>');
        jQuery('.news-preview .news-interior').empty();
        jQuery('.news-list .news-interior').empty();
        jQuery('.news-list .news-interior').addClass('active');
        if (jQuery(window).width() > tabletBreakpoint) {
          jQuery([document.documentElement, document.body]).animate({
            scrollTop: jQuery('.news-list .news-interior').offset().top
          }, 650);
        }

    fetch(postURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){
        var imageU = post._embedded['wp:featuredmedia'][0].source_url;
        var linkID = post.acf.relate_to_story;
        var audioTitle = post.acf.audio_title;
        var audio = post.acf.audio_file;
        var audioImage = post.acf.audio_image;
        if(imageU) {
          jQuery('.news-preview .news-interior').append('<div class="mediaWrapper" style="background-image:url(' + imageU + ')"></div>');
        };               


        jQuery('.news-preview h2 span').append(':');
        jQuery('.news-preview h2').append('<br/>' + post.title.rendered + '');
        jQuery('.news-list .news-interior').append(post.content.rendered);

        if(audio) {
            jQuery('.news-list .news-interior').append('<div class="audio-container"><audio id="audio-player" preload="true" src="'+ audio + '"></audio><div class="audio-controls"><button id="pButton" class="play""></button></div><div class="art-layer" style="background-image:url(' + audioImage + ')"></div></div><div id="timeline"><div id="playhead"></div></div>');

            jQuery('#pButton').click(function(e) { 
              play();
            });               



            var audioPlayer = document.getElementById('audio-player'); // id for audio element
            var duration; // Duration of audio clip
            var pButton = document.getElementById('pButton'); // play button
            var playhead = document.getElementById('playhead'); // playhead
            var timeline = document.getElementById('timeline'); // timeline
            // timeline width adjusted for playhead
            var timelineWidth = timeline.offsetWidth - playhead.offsetWidth;
            // timeupdate event listener
            audioPlayer.addEventListener("timeupdate", timeUpdate, false);


            //Makes timeline clickable
            timeline.addEventListener("click", function(event) {
              moveplayhead(event);
              audioPlayer.currentTime = duration * clickPercent(event);
            }, false);

            // returns click as decimal (.77) of the total timelineWidth
            function clickPercent(e) {
              return (e.pageX - timeline.offsetLeft) / timelineWidth;
            }

            // Makes playhead draggable
            playhead.addEventListener('mousedown', mouseDown, false);
            window.addEventListener('mouseup', mouseUp, false);

            // Boolean value so that mouse is moved on mouseUp only when the playhead is released
            var onplayhead = false;
            // mouseDown EventListener
            function mouseDown() {
              onplayhead = true;
              window.addEventListener('mousemove', moveplayhead, true);
              audioPlayer.removeEventListener('timeupdate', timeUpdate, false);
            }
            // mouseUp EventListener
            // getting input from all mouse clicks
            function mouseUp(e) {
              if (onplayhead == true) {
                moveplayhead(e);
                window.removeEventListener('mousemove', moveplayhead, true);
                audioPlayer.currentTime = duration * clickPercent(e);
                audioPlayer.addEventListener('timeupdate', timeUpdate, false);
              }
              onplayhead = false;
            }
            // mousemove EventListener
            // Moves playhead as user drags
            function moveplayhead(e) {
              var newMargLeft = e.pageX - timeline.offsetLeft;
              if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
                playhead.style.marginLeft = newMargLeft + "px";
              }
              if (newMargLeft < 0) {
                playhead.style.marginLeft = "0px";
              }
              if (newMargLeft > timelineWidth) {
                playhead.style.marginLeft = timelineWidth + "px";
              }
            }

            // timeUpdate
            // Synchronizes playhead position with current point in audio
            function timeUpdate() {
              var playPercent = timelineWidth * (audioPlayer.currentTime / duration);
              playhead.style.marginLeft = playPercent + "px";
              if (audioPlayer.currentTime == duration) {
                pButton.className = "";
                pButton.className = "play";
              }
            }

            //Play and Pause
            function play() {
              // start audioPlayer
              if (audioPlayer.paused) {
                audioPlayer.play();
                // remove play, add pause
                pButton.className = "";
                pButton.className = "pause";
              } else { // pause audioPlayer
                audioPlayer.pause();
                // remove pause, add play
                pButton.className = "";
                pButton.className = "play";
              }
            }

            // Gets audio file duration
            audioPlayer.addEventListener("canplaythrough", function() {
              duration = audioPlayer.duration;
            }, false);


        }
   


            var basicURL = site.root + '/wp-json/wp/v2/story/';
            var fullURL = basicURL + linkID;

            fetch(fullURL, {
              method: 'GET',
              headers:{
                'Content-Type': 'application/json',
                'accept': 'application/json',
              }
            }).then(function(response){
              return response.json();
            }).then(function(post){
              
            jQuery('.news-list .news-interior').append('<h6>View the story</h6><span class="arrow-link"><a href="' + post.link + '">' + post.title.rendered + '</a></span>');

           });


      
    });    


    });

  }


function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


  function loadPostByID() {
      var postID = getParameterByName('postid');
    
        var baseParam = '?id=';
        var posturlID = site.root + '/wp-json/wp/v2/posts';
        var postIDURL = posturlID + '/' + postID + '?_embed';
        jQuery('.news-preview h2').html('<span>In the news</span>');
        jQuery('.news-preview .news-interior').empty();
        jQuery('.news-list .news-interior').empty();
        jQuery('.news-list .news-interior').addClass('active');
        if (jQuery(window).width() > tabletBreakpoint) {
          jQuery([document.documentElement, document.body]).animate({
            scrollTop: jQuery('.news-list .news-interior').offset().top
          }, 650);
        }

    fetch(postIDURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){
        var imageU = post._embedded['wp:featuredmedia'][0].source_url;
        var linkID = post.acf.relate_to_story;
        var audioTitle = post.acf.audio_title;
        var audio = post.acf.audio_file;
        var audioImage = post.acf.audio_image;
        if(imageU) {
          jQuery('.news-preview .news-interior').append('<div class="mediaWrapper" style="background-image:url(' + imageU + ')"></div>');
        };               
        jQuery('.news-preview h2 span').append(':');
        jQuery('.news-preview h2').append('<br/>' + post.title.rendered + '');
        jQuery('.news-list .news-interior').append(post.content.rendered);


        if(audio) {
            jQuery('.news-list .news-interior').append('<div class="audio-container"><audio id="audio-player" preload="true" src="'+ audio + '"></audio><div class="audio-controls"><button id="pButton" class="play""></button></div><div class="art-layer" style="background-image:url(' + audioImage + ')"></div></div><div id="timeline"><div id="playhead"></div></div>');

            jQuery('#pButton').click(function(e) { 
              play();
            });               



            var audioPlayer = document.getElementById('audio-player'); // id for audio element
            var duration; // Duration of audio clip
            var pButton = document.getElementById('pButton'); // play button
            var playhead = document.getElementById('playhead'); // playhead
            var timeline = document.getElementById('timeline'); // timeline
            // timeline width adjusted for playhead
            var timelineWidth = timeline.offsetWidth - playhead.offsetWidth;
            // timeupdate event listener
            audioPlayer.addEventListener("timeupdate", timeUpdate, false);


            //Makes timeline clickable
            timeline.addEventListener("click", function(event) {
              moveplayhead(event);
              audioPlayer.currentTime = duration * clickPercent(event);
            }, false);

            // returns click as decimal (.77) of the total timelineWidth
            function clickPercent(e) {
              return (e.pageX - timeline.offsetLeft) / timelineWidth;
            }

            // Makes playhead draggable
            playhead.addEventListener('mousedown', mouseDown, false);
            window.addEventListener('mouseup', mouseUp, false);

            // Boolean value so that mouse is moved on mouseUp only when the playhead is released
            var onplayhead = false;
            // mouseDown EventListener
            function mouseDown() {
              onplayhead = true;
              window.addEventListener('mousemove', moveplayhead, true);
              audioPlayer.removeEventListener('timeupdate', timeUpdate, false);
            }
            // mouseUp EventListener
            // getting input from all mouse clicks
            function mouseUp(e) {
              if (onplayhead == true) {
                moveplayhead(e);
                window.removeEventListener('mousemove', moveplayhead, true);
                // change current time
                audioPlayer.currentTime = duration * clickPercent(e);
                audioPlayer.addEventListener('timeupdate', timeUpdate, false);
              }
              onplayhead = false;
            }
            // mousemove EventListener
            // Moves playhead as user drags
            function moveplayhead(e) {
              var newMargLeft = e.pageX - timeline.offsetLeft;
              if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
                playhead.style.marginLeft = newMargLeft + "px";
              }
              if (newMargLeft < 0) {
                playhead.style.marginLeft = "0px";
              }
              if (newMargLeft > timelineWidth) {
                playhead.style.marginLeft = timelineWidth + "px";
              }
            }

            // timeUpdate
            // Synchronizes playhead position with current point in audio
            function timeUpdate() {
              var playPercent = timelineWidth * (audioPlayer.currentTime / duration);
              playhead.style.marginLeft = playPercent + "px";
              if (audioPlayer.currentTime == duration) {
                pButton.className = "";
                pButton.className = "play";
              }
            }

            //Play and Pause
            function play() {
              // start audioPlayer
              if (audioPlayer.paused) {
                audioPlayer.play();
                // remove play, add pause
                pButton.className = "";
                pButton.className = "pause";
              } else { // pause audioPlayer
                audioPlayer.pause();
                // remove pause, add play
                pButton.className = "";
                pButton.className = "play";
              }
            }

            // Gets audio file duration
            audioPlayer.addEventListener("canplaythrough", function() {
              duration = audioPlayer.duration;
            }, false);


        }
   


            var basicURL = site.root + '/wp-json/wp/v2/story/';
            var fullURL = basicURL + linkID;

            fetch(fullURL, {
              method: 'GET',
              headers:{
                'Content-Type': 'application/json',
                'accept': 'application/json',
              }
            }).then(function(response){
              return response.json();
            }).then(function(post){
            var audioTitle = post.acf.audio_title;
            var audio = post.acf.audio_file;
            var audioImage = post.acf.audio_image;

            if (audio == false) {
              jQuery('.news-list .news-interior').append('<div class="audio-container"><audio id="audio-player" preload="true" src=""></audio><div class="audio-controls"><button id="pButton" class="play" onclick="play()"></button></div><div class="art-layer" style="background-image:url()"></div></div><div id="timeline"><div id="playhead"></div></div>');
              
            }


            jQuery('.news-list .news-interior').append('<h6>View the story</h6><span class="arrow-link"><a href="' + post.link + '">' + post.title.rendered + '</a></span>');

           });


      
    });    



  }


 function accessibleMenu() {
    jQuery('.categories h3').keypress(function(e) { 
      jQuery(this).parent().toggleClass('tab-active');
      });

  }


  function loadNav() {
    jQuery('.nav').toggleClass('nav-active');
  }

  function loadCategoriesbyType() {  
    var baseURL = site.root + '/wp-json/wp/v2/storiestypes';


    fetch(baseURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){

      var storyCategories = ('.story-categories');

      jQuery.each(post, function(i, item) {
        jQuery('.categories-wrapper ul').append('<li class="story-category-wrapper" rel="' + post[i].id + '" ><span class="h2style">' + post[i].name + '</span><p>' + post[i].count + ' stories</p><div rel="' + post[i].id + '" class="story-category"></div></li>');
      });               

      jQuery('.categories h3').keypress(function(e) { 
        jQuery(this).parent().addClass("tab-active");
        jQuery('.categories-wrapper ul li').attr( 'tabIndex', 0 );
      });               


      if (jQuery(window).width() < tabletBreakpoint) {
        jQuery('.categories h3').click(function(e) { 
          jQuery(this).parent().toggleClass("tab-active");
          jQuery('.categories-wrapper ul li').attr( 'tabIndex', 0 );
        });               

        }

      $('.categories').on('focusout', function (e) {
          setTimeout(function () { // needed because nothing has focus during 'focusout'
              if ($(':focus').closest('.categories').length <= 0) {
                  jQuery('.categories').removeClass("tab-active");
                  jQuery('.categories-wrapper ul li').attr( 'tabIndex', -1 );

              }
          }, 0);
      });

      jQuery('.story-category-wrapper').on('keypress click', function(e){
      if (e.which === 13 || e.type === 'click') {
        jQuery('.categories-message').empty();
        jQuery('.categories-message').addClass('is-loading');
        jQuery('.categories').removeClass("tab-active");
        jQuery('._wrapper').addClass('is-loading');
        setTimeout(function() {                
          jQuery('.categories-message').empty();
          jQuery('.categories-message').append('Loading Stories');
          jQuery('.categories-message').addClass('is-loading');

        },1);
      

        setTimeout(function() {    
        jQuery('.categories').addClass("selected");

          setTimeout(function() {                
                jQuery('.categories').blur();
                jQuery('.categories').removeClass("selected");
            },500);
        },1);

        if (jQuery(window).width() > tabletBreakpoint + 1) {
        jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery('._wrapper').offset().top - 150
        }, 650);
        
        }

        if (jQuery(window).width() < tabletBreakpoint) {
        jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery('._wrapper').offset().top
        }, 650);
        

        }

        var postID = jQuery(this).attr('rel');

        var baseURL = site.root + '/wp-json/wp/v2/story?storiestypes';
        var fullURL = baseURL + '=' + postID + '&_embed&per_page=100';

        fetch(fullURL, {
          method: 'GET',
          headers:{
            'Content-Type': 'application/json',
            'accept': 'application/json',
          }
        }).then(function(response){
          return response.json();
        }).then(function(post){

        jQuery('._wrapper').empty();

          jQuery.each(post, function(i, item) {

            var imageUi = post[i]._embedded['wp:featuredmedia'][0].source_url;
            var portrait = post[i].acf.portrait_thumbnail_optional;

            if (portrait != null) {
                      var imageU = portrait;
              
            }

            jQuery('._wrapper').append('<a  href="' + post[i].link + '" class="card card-slide"><div class="content-wrapper"><h2>' + post[i].title.rendered + '</h2><div class="story-icon"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 369 369" style="enable-background:new 0 0 369 369;" xml:space="preserve"><polygon points="63,0 63,43.1 278,190 63,328.4 63,369 341,190 "></polygon></svg></div></div>' + post[i].title.rendered + '<div class="mediaWrapper" style="background-image:url(' + imageUi + ')"></div></a>');

          });

          var baseURL = site.root + '/wp-json/wp/v2/storiestypes';
          var fullURL = baseURL + '/' + postID;

          fetch(fullURL, {
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'accept': 'application/json',
            }
          }).then(function(response){
            return response.json();
          }).then(function(post){
            var imageU = post.acf.story_bg_videos[0].image;
            var videoMP4 = post.acf.story_bg_videos[0].video_mp4;
            var videoOGG = post.acf.story_bg_videos[0].video_ogg;
            jQuery('._wrapper  > a:nth-child(2)').after('<a class="card card-slide card-slide-wide white card-slide-category-wide"><p class="large">' + post.name + '</p><div class="video-cover-wrap"><div class="video-cover-inner jquery-background-video-wrapper"><video class="jquery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + imageU + '" muted loop autoplay><source src="' + videoMP4 + '" type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div></a>');
            jQuery('[data-bgvideo]').bgVideo();

              setTimeout(function() {                
                    jQuery('._wrapper').removeClass('is-loading');
                    jQuery('.categories-message').removeClass('is-loading');
                     jQuery('.categories-message').empty();
                    jQuery('.categories-message').append('View All Stories');
                    jQuery('.categories-message').addClass('is-loaded');
                    jQuery('.categories').addClass('message');


                }, 1);




          });    
        });    


      };



      });                       

    });    
}


  function loadCategoriesbyID() {  
    if (window.location.href.indexOf('?collection=') > 0) {
      var collectionID = getParameterByName('collection');
      var collectionURL = site.root + '/wp-json/wp/v2/storiestypes';
      var baseURL = site.root + '/wp-json/wp/v2/story?storiestypes';
      var fullURL = baseURL + '=' + collectionID + '&_embed&per_page=100';





      var collectionID = getParameterByName('collection');

        var baseURL = site.root + '/wp-json/wp/v2/story?storiestypes';
        var fullURL = baseURL + '=' + collectionID + '&_embed&per_page=100';


    fetch(fullURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){






          jQuery.each(post, function(i, item) {

            var imageUi = post[i]._embedded['wp:featuredmedia'][0].source_url;
            var portrait = post[i].acf.portrait_thumbnail_optional;

            if (portrait != null) {
                      var imageU = portrait;
              
            }

            jQuery('._wrapper').append('<a  href="' + post[i].link + '" class="card card-slide"><div class="content-wrapper"><h2>' + post[i].title.rendered + '</h2><div class="story-icon"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 369 369" style="enable-background:new 0 0 369 369;" xml:space="preserve"><polygon points="63,0 63,43.1 278,190 63,328.4 63,369 341,190 "></polygon></svg></div></div>' + post[i].title.rendered + '<div class="mediaWrapper" style="background-image:url(' + imageUi + ')"></div></a>');
              
          });



          var collectionURL = site.root + '/wp-json/wp/v2/storiestypes';
          var collectionFullURL = collectionURL + '/' + collectionID;

          fetch(collectionFullURL, {
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'accept': 'application/json',
            }
          }).then(function(response){
            return response.json();
          }).then(function(post){



            var imageU = post.acf.story_bg_videos[0].image;
            var videoMP4 = post.acf.story_bg_videos[0].video_mp4;
            var videoOGG = post.acf.story_bg_videos[0].video_ogg;
            jQuery('._wrapper  > a:nth-child(2)').after('<a class="card card-slide card-slide-wide white"><p class="large">' + post.name + '</p><div class="video-cover-wrap"><div class="video-cover-inner jquery-background-video-wrapper"><video class="jquery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + imageU + '" muted loop autoplay><source src="' + videoMP4 + '" type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div></a>');
            jQuery('[data-bgvideo]').bgVideo();

              setTimeout(function() {                
                    jQuery('._wrapper').removeClass('is-loading');
                    jQuery('.categories-message').removeClass('is-loading');
                     jQuery('.categories-message').empty();
                    jQuery('.categories-message').append('View All Stories');
                    jQuery('.categories-message').addClass('is-loaded');

                    jQuery('._wrapper  > a:nth-child(1)').focus();

                }, 1);








      }); 



              setTimeout(function() {                
                jQuery([document.documentElement, document.body]).animate({
                  scrollTop: jQuery('._wrapper').offset().top 
                }, 650);



                }, 1);

           



      });                       

       };

}


function unloadCategories() {  
  jQuery('._wrapper').empty();
  jQuery('._wrapper').addClass('is-loading');
  var baseURL = site.root + '/wp-json/wp/v2/story?_embed&per_page=1';
        jQuery('.categories-message').append('Loading Stories');
        jQuery('.categories-message').addClass('is-loading');

  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
    var storyCategories = ('.story-categories');
    jQuery.each(post, function(i, item) {

      var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url
      var portrait = post[i].acf.portrait_thumbnail_optional
            var videoMP4 = post[i].acf.story_bg_videos.video_mp4
            var videoOGG = post[i].acf.story_bg_videos.video_ogg
            var videoWM = post[i].acf.story_bg_videos.video_webm
        if (portrait === '') {
            var portrait = imageU;
        }


      jQuery('._wrapper').append('<div class="card card-slide card-slide-wide"><a href="' + post[i].link + '"><div class="card-slide-wide-title"><div class="button small play white"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 369 369" style="enable-background:new 0 0 369 369;" xml:space="preserve"><polygon points="341,190 63,369 63,0 "></polygon></svg></div><h3 class="h2Style">' + post[i].title.rendered + '</h3></div><div class="video-cover-wrap"><div class="video-cover-inner jquery-background-video-wrapper"><video class="jquery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + imageU + '" muted loop autoplay><source src="' + videoMP4 + '" type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div></a></div>');
      
      jQuery('[data-bgvideo]').bgVideo();



    });               

  });  

  var baseURL = site.root + '/wp-json/wp/v2/story?_embed&per_page=100&offset=1';

  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
    var storyCategories = ('.story-categories');

    jQuery.each(post, function(i, item) {

      var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url
      var portrait = post[i].acf.portrait_thumbnail_optional
      if (portrait != null) {
                var imageU = portrait;

      }
      jQuery('._wrapper').append('<a  href="' + post[i].link + '" class="card card-slide"><div class="content-wrapper"><h2>' + post[i].title.rendered + '</h2><div class="story-icon"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 369 369" style="enable-background:new 0 0 369 369;" xml:space="preserve"><polygon points="63,0 63,43.1 278,190 63,328.4 63,369 341,190 "></polygon></svg></div></div>' + post[i].title.rendered + '<div class="mediaWrapper" style="background-image:url(' + imageU + ')"></div></a>');
    });        



    setTimeout(function() {                
      jQuery('._wrapper  > a:nth-child(5)').after('<div class="card-slide card-slide-wide white"><p class="overview">Small Forces spotlights people working hard to make their communities stronger. Our stories amplify bright ideas for important changes. We invite you to watch and be inspired.</p></div>');

    setTimeout(function() {                
        jQuery('._wrapper').removeClass('is-loading');
        jQuery('.categories-message').removeClass('is-loading');
                      jQuery('.categories-message').empty();


    },1);


    }, 1);



  });  


}        

// function loadVideo() {
//   var baseURL = site.root + '/wp-json/wp/v2/story';

//   fetch(baseURL, {
//     method: 'GET',
//     headers:{
//         'Content-Type': 'application/json',
//         'accept': 'application/json',
//     }
//     }).then(function(response){
//         return response.json();
//     }).then(function(post){
//         var storyCategories = ('.story-categories');

//         jQuery.each(post, function(i, item) {
//             jQuery('.categories-wrapper').append('<div class="slide-element ' + post[i].slug + '"><div class="content-wrapper"><h2>' + post[i].name + '</h2><h3>' + post[i].count + ' stories</h3><div class="story-icon ' + post[i].slug + '"></div></div><a href="' + post[i].link + '" rel="' + post[i].id + '"></a><div class="mediaWrapper"></div></div>');
//         });

//     });    


// }

function loadStoryPromotion() {

  var baseURL = site.root + '/wp-json/wp/v2/story/?_embed&filter[orderby]=date&order=desc&per_page=1';
  var fullURL = baseURL;

  fetch(fullURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){


    jQuery.each(post, function(i, item) {

      var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url
      var portrait = post[i].acf.portrait_thumbnail_optional
          var videoMP4 = post[i].acf.story_bg_videos.video_mp4
          var videoOGG = post[i].acf.story_bg_videos.video_ogg
          var videoWM = post[i].acf.story_bg_videos.video_webm
        if (portrait === '') {
            var portrait = imageU;
        }

      var title = post[i].title.rendered;
      jQuery('.storyPromotion').prepend('<div class="promotionContent"><div class="text"><p><span>Now Playing:</span><br/>' + title +'</p></div></div><div class="mediaWrapper" id="storyNowOn"><div class="video-cover-wrap"><div class="video-cover-inner jQuery-background-video-wrapper"><video class="jQuery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + (post[i]._embedded['wp:featuredmedia'][0].source_url) + '" muted loop><source src="' + videoMP4 +'"  type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div></div></div>'); 

      jQuery('[data-bgvideo]').bgVideo();

    })    



  });

    jQuery('.storyPromotion').hover(function(e) { 
    jQuery('.storyPromotion video.jQuery-background-video')[0].play();
    }, function(){
   setTimeout(function() {
   jQuery('.storyPromotion video.jQuery-background-video')[0].pause();
    }, 500);
 });

}
function loadImpactPromotion() {

  var impactURL = site.root + '/wp-json/wp/v2/pages/1852';

  fetch(impactURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){

      var portrait = post.acf.impact_art[0].video_art;
      var videoMP4 = post.acf.impact_art[0].video_mp4;
      var videoOGG = post.acf.impact_art[0].video_ogg;
      var videoOGG = post.acf.impact_art[0].video_ogg;

      jQuery('.impactPromotion').append('<div class="mediaWrapper" id="MakeBigChange"><div class="video-cover-wrap"><div class="video-cover-inner jQuery-background-video-wrapper"><video class="jQuery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + portrait +'" muted loop><source src="' + videoMP4 + '"  type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div>'); 
     
      jQuery('[data-bgvideo]').bgVideo();


          var latestImpact = site.root + '/wp-json/wp/v2/impact/?_embed&filter[orderby]=date&order=desc&per_page=1';

          fetch(latestImpact, {
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'accept': 'application/json',
            }
          }).then(function(response){
            return response.json();
          }).then(function(post){
      
          jQuery.each(post, function(i, item) {
                  var impactType = post[i].Impacttypes;

                  var impactTypes = site.root + '/wp-json/wp/v2/impacttypes';
                  var collectionFullURL = impactTypes + '/' + impactType;
                  fetch(collectionFullURL, {
                    method: 'GET',
                    headers:{
                      'Content-Type': 'application/json',
                      'accept': 'application/json',
                    }
                  }).then(function(response){
                    return response.json();
                  }).then(function(post){
                    console.log(post.link);
                    console.log(post.name);
                    console.log(post.slug);
                      var name = post.name;

                      jQuery('.impactPromotion').append('<div class="promotionContent"><div class="text"><p><span>Latest Impact:</span><br/>' + name + '</p></div></div>'); 


        })

          })    



             



            })    

    })    
    jQuery('.impactPromotion').hover(function(e) { 
    jQuery('.impactPromotion video.jQuery-background-video')[0].play();
    }, function(){
   setTimeout(function() {
   jQuery('.impactPromotion video.jQuery-background-video')[0].pause();
    }, 500);


  });

}

function loadAboutPromotion() {

  var impactURL = site.root + '/wp-json/wp/v2/pages/4';

  fetch(impactURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){

      var portrait = post.acf.about_video[0].video_art;
      var videoMP4 = post.acf.about_video[0].video_mp4;
      var videoOGG = post.acf.about_video[0].video_ogg;

      jQuery('.aboutPromotion').append('<div class="mediaWrapper" id="MakeBigChange"><div class="video-cover-wrap"><div class="video-cover-inner jQuery-background-video-wrapper"><video class="jQuery-background-video" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + portrait +'" muted loop><source src="' + videoMP4 + '"  type="video/mp4"><source src="' + videoOGG + '" type="video/ogg"></video></div></div>'); 
      jQuery('[data-bgvideo]').bgVideo();



    });

  jQuery('.aboutPromotion').hover(function(e) { 
    jQuery('.aboutPromotion video.jQuery-background-video')[0].play();
    }, function(){
    setTimeout(function() {
     jQuery('.aboutPromotion video.jQuery-background-video')[0].pause();
    }, 500);

  });

}

function promotionControls() {

  jQuery('.promotion').hover(function(e) { 
    jQuery(this).addClass('hover');
    jQuery(this).siblings().addClass('hide');
  }, function(){
    jQuery(this).siblings().removeClass('hide');
    jQuery(this).removeClass('hover');
  });

  setTimeout(function() {
    jQuery('.mainPromotion').removeClass('loading');
  }, 1500);

  return; 
}





function loadAboutContent() {

  var baseURL = site.root + '/wp-json/wp/v2/story/?_embed&filter[orderby]=date&order=desc&per_page=1';
  var fullURL = baseURL;

  fetch(fullURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){


    jQuery.each(post, function(i, item) {
      
      var title = post[i].title.rendered;
      jQuery('.storyPromotion').prepend('<div class="promotionContent"><div class="text"><div class="button large circle play"><svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 369 369" style="enable-background:new 0 0 369 369;" xml:space="preserve"><polygon points="341,190 63,369 63,0 "/></svg></div><p>Now Playing:<br/>' + title +'</p></div></div></div><div class="mediaWrapper" id="storyNowOn"><div class="video-cover-wrap"><div class="video-cover-inner jQuery-background-video-wrapper" style="background-image:url(' + (post[i]._embedded['wp:featuredmedia'][0].source_url) + ')"><video class="jQuery-background-video is-playing is-visible" data-bgvideo="true" data-bgvideo-fade-in="0" data-bgvideo-show-pause-play="false" data-bgvideo-pause-after="0" poster="' + (post[i]._embedded['wp:featuredmedia'][0].source_url) + '" muted loop autoplay="true"><source src="http://localhost:8888/sf2020/wp-content/uploads/2020/01/CCK_Background-Video.mp4"  type="video/mp4"><source src="http://localhost:8888/sf2020/wp-content/uploads/2020/01/CCK_Background-Video.webm" type="video/ogg"></video></div></div></div></div>'); });



    var title = post[i].title.rendered;

  });




  return; 
}

function run(list, index) {
  list.filter('.deactive').fadeOut(0).removeClass('active').removeClass('deactive');
  list.eq(index).fadeIn(0).addClass('bottom').animate({opacity:1}, 500, function(){
    jQuery(this).removeClass('bottom');
    jQuery(this).addClass('active').animate({opacity:1}, 2000, function(){
      jQuery(this).addClass('deactive');
    });
  });
index = (index + 1) % list.length; // Wraps around if it hits the end
setTimeout(function() {
  run(list, index);
}, 2250);
}

jQuery('.circle.more').on('keypress click', function(e){
if (e.which === 13 || e.type === 'click') {
  jQuery(this).parent().parent().toggleClass('open');
};
});


function playHoverVideo() {
}

function pauseVideo() {
  jQuery('video.jQuery-background-video.is-playing')[0].pause();
}
function closeVideo() {
   jQuery('body').removeClass('video');
  jQuery('.story-video').remove();
  jQuery('.story-preview-video').fadeIn();

          setTimeout(function() {
            jQuery('.button.play').focus();
          }, 5);



}
function playVideo() {
  player = new Vimeo.Player(jQuery('.story-video iframe')[0]);
  player.play();
  window.location.hash = 'play';
}

function loadStoryImpact() {
  jQuery('.story-impact-more-open').click(function(e) { 
    jQuery('.story-impact-more-close').addClass('open');
    jQuery(this).addClass('clicked');
    var pageId = jQuery(this).attr('rel');
    var baseURL = site.root + '/wp-json/wp/v2/story/' + pageId + '?per_page=1';
    fetch(baseURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){
      var impact_overview = post.acf.impact_overview;
      jQuery('.story-impact-content').append('<div class="story-impact-content-detail">' + impact_overview + '<h3 class="arrow-link"><a href="/community-impact">View More Impact</a></h3></div>');
      jQuery('.callout-module-wrapper').addClass('active');

    });    



  });

}

function closeStoryImpact() {
  jQuery('.story-impact-more-close').click(function(e) { 
    jQuery('.story-impact-more-open').removeClass('clicked');
    jQuery(this).removeClass('open');
    jQuery('.story-impact-content-detail').remove();
    jQuery('.callout-module-wrapper.active').removeClass('active');
    jQuery(this).removeClass('clicked');

  });
}

function loadVideo() {
  var pageId = jQuery(this).attr('rel');
  var baseURL = site.root + '/wp-json/wp/v2/story/' + pageId;
  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
   jQuery('body').addClass('video');
   jQuery('.story-header-wrapper').append('<div class="story-video"><div class="story-video-wrapper"><div class="story-video-wrapper-detail"><h2><span>Now Playing:</span> ' + post.title.rendered + '</h2><button class="close button button-close">x</button><div class="story-video-wrapper-inner"><div class="video_holder"><div class="video_thumbnail playing" style="background-image:url()"><span class="play button">Play</span></div><iframe src="https://player.vimeo.com/video/' + post.acf.video_url + '?api=1" width="640" height="360" frameborder="0" allowfullscreen="allowfullscreen" id="video" data-ready="true"></iframe></h2></div></div></div><div class="story-video-links"><button class="story-video-link-related category-open arrow-link"> Related Stories</button><button class="story-video-link-related category-open arrow-link"> More about ' + post.title.rendered + '</button></div></div></div>');
   

   jQuery([document.documentElement, document.body]).animate({
    scrollTop: jQuery('.story-header-wrapper').offset().top
  }, 350);


   player = new Vimeo.Player(jQuery('.story-video iframe')[0]);


   player.on('ended', function() {

    jQuery('.video_thumbnail').removeClass('playing');
    jQuery('.story-video-categories').addClass('ended');

  });


   jQuery('.button-close').click(function(e) { 
    closeVideo();
  });

   var storiestypes = post.storiestypes + '';
   var storiestypesArr = storiestypes.split(',');
   
   const storiestype = storiestypesArr;
   storiestype.forEach(function(storytype){

    var basicURL = site.root + '/wp-json/wp/v2/story?storiestypes=';
    var fullURL = basicURL + storytype + '&_embed&per_page=2&exclude=' + pageId;
    fetch(fullURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){

     jQuery.each(post, function(i, item) {
      var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url

      jQuery('.story-video-categories-videos').append('<div class="story-video-categories-video" style="background-image:url(' + post[i].acf.image_array[0].image + ')"><p  class="related-story" rel="'+ post[i].id + '">'+ post[i].title.rendered + '</p><div>');

    });

   });

  });
   
 });    


}





function loadStory() {

  var baseURL = site.root + '/wp-json/wp/v2/storiestypes';

  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
    var storyCategories = ('.story-categories');

    jQuery.each(post, function(i, item) {
      jQuery('.categories').append('<div class="slide-element ' + post[i].slug + '"><div class="content-wrapper"><h2>' + post[i].name + '</h2><h3>' + post[i].count + ' stories</h3><div class="story-icon ' + post[i].slug + '"></div></div><a href="' + post[i].link + '" rel="' + post[i].id + '"></a><div class="mediaWrapper"></div></div>');
    });
    
  });    


}


function loadImpact() {

  var baseURL = site.root + '/wp-json/wp/v2/impact';

  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){

    jQuery.each(post, function(i, item) {
      jQuery('.impact-all .wrapper').append('<div class="content-wrapper"><h2>' + post[i].title.rendered + '</h2></div>');
    });
    
  });    


}

function loadThumbails() {
  var baseURL = site.root + '/wp-json/wp/v2/story?_embed&per_page=12';

  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
    var storyCategories = ('.story-categories');

    jQuery.each(post, function(i, item) {

      var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url
      var portrait = post[i].acf.portrait_thumbnail_optional
      if (portrait != null) {
                var imageU = portrait;

      }
      jQuery('._wrapper').append('<div class="card card-slide"><div class="mediaWrapper" style="background-image:url(' + imageU + ')"></div></div>');
    });        




  });  
   


}

  if (jQuery('body').hasClass('page-template-page-contact')) {
    loadThumbails();
  }



if (jQuery('body').hasClass('page-template-page-impact')) { 
  var baseURL = site.root + '/wp-json/wp/v2/pages/1852';
  fetch(baseURL, {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json',
      'accept': 'application/json',
    }
  }).then(function(response){
    return response.json();
  }).then(function(post){
   jQuery('.wrapper  > div:nth-child(4)').after('<div class="impact wide">' + post.content.rendered + '</div>');
   jQuery('.wrapper  > div:nth-child(11)').after('<div class="impact wide">' + post.acf.impact_secondary.content + '</div>');

 });    

  if (jQuery(window).width() < tabletBreakpoint) {

  var pageHeader = jQuery('.page-header').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.page-header').addClass("fixed");
    } else {
      jQuery('.page-header').removeClass("fixed");
    }
  });

  var pageHeaderDown = jQuery('.section-break').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.impact-list').addClass("bottom");
      jQuery('.page-header').addClass("bottom");
    } else {
      jQuery('.impact-list').removeClass("bottom");
      jQuery('.page-header').removeClass("bottom");
    }
  }, {
    offset: '100%'
  });
  }
 if (jQuery(window).width() > tabletBreakpoint) {


  var controller = new ScrollMagic.Controller();
  var controller2 = new ScrollMagic.Controller();


  var tween3 = TweenMax.to(".text-list-wrapper.list-one .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-one", duration: 500, offset:100 })
  .setTween(tween3)
  .addTo(controller);

  var tween3a = TweenMax.to(".text-list-wrapper.list-two .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-two", duration: 500, offset: 50 })
  .setTween(tween3a)
  .addTo(controller);

  var tween3b = TweenMax.to(".text-list-wrapper.list-three .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-three", duration: 500, offset: 50 })
  .setTween(tween3b)
  .addTo(controller);

  var tween3c = TweenMax.to(".text-list-wrapper.list-four .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-four", duration: 500, offset: 50 })
  .setTween(tween3c)
  .addTo(controller);

  var tween3d = TweenMax.to(".text-list-wrapper.list-five .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-five", duration: 500, offset: 50 })
  .setTween(tween3d)
  .addTo(controller);
}

}

if (jQuery('body').hasClass('home')) { 
  var list = jQuery('.cycle_statement').children();
  if (list.length > 0) {
    run(list, 0);
  }
   promotionControls();
  loadStoryPromotion();
  loadImpactPromotion();

 if (jQuery(window).width() > tabletBreakpoint) {

  var controller = new ScrollMagic.Controller();
  var controller2 = new ScrollMagic.Controller();


  var tween3 = TweenMax.to(".text-list-wrapper.list-one .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-one", duration: 500, offset:-200 })
  .setTween(tween3)
  .addTo(controller);

  var tween3s = TweenMax.to(".list .circle-local", 1, {className:"circle circle-local scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-one", duration: 500, offset:0 })
  .setTween(tween3s)
  .addTo(controller);

  var tween3t = TweenMax.to(".list .circle-global", 1, {className:"circle circle-global scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-four", duration: 500, offset:-200 })
  .setTween(tween3t)
  .addTo(controller);

  var tween3a = TweenMax.to(".text-list-wrapper.list-two .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-two", duration: 500, offset:-200 })
  .setTween(tween3a)
  .addTo(controller);

  var tween3b = TweenMax.to(".text-list-wrapper.list-three .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-three", duration: 500, offset:-200 })
  .setTween(tween3b)
  .addTo(controller);

  var tween3c = TweenMax.to(".text-list-wrapper.list-four .line-first", 1, {className:"line line-first scrolling", ease:Linear.easeNone});
  var scene = new ScrollMagic.Scene({triggerElement: ".list-four", duration: 500, offset:-200 })
  .setTween(tween3c)
  .addTo(controller);

}


}




if (jQuery('body').hasClass('page-template-page-news')) { // 
  loadPost();
  if (window.location.href.indexOf('?postid=') > 0) {
    loadPostByID();
  };


}

if (jQuery('body').hasClass('post-type-archive-story')) { // 
  if (window.location.href.indexOf('?collection=') < 0) {
    unloadCategories();
    loadCategoriesbyType();
  }

  if (window.location.href.indexOf('?collection=') > 0) {
    loadCategoriesbyID();
       loadCategoriesbyType();
 
  }

    setTimeout(function() {                

    jQuery('.categories-message').click(function(e) { 
      jQuery('._wrapper').empty();
      jQuery('.categories-message').empty();
        jQuery('.categories-message').removeClass('is-loading');
        setTimeout(function() {                

        unloadCategories();
        }, 1);

        jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery('._wrapper').offset().top - 150
        }, 650);

      });

    }, 500);

}
if (jQuery('body').hasClass('post-type-archive-story')) { // 
promotionControls();

  loadAboutPromotion()
  loadImpactPromotion()

}
if (jQuery('body').hasClass('page-template-page-about')) { // 
promotionControls();

  loadStoryPromotion();
  loadImpactPromotion()

}
if (jQuery('body').hasClass('page-template-page-news')) { // 
promotionControls();

  loadStoryPromotion();
  loadImpactPromotion()

}
if (jQuery('body').hasClass('page-template-page-impact')) { // 
promotionControls();
  loadStoryPromotion();
  loadAboutPromotion()

}


if (jQuery('body').hasClass('single-story')) { // single-story
  loadStoryImpact();
  closeStoryImpact();
    height = jQuery(window).height()-70

  var storyTitleMeta = jQuery('.story-header-wrapper').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.story-title-meta').addClass("fixed");
    } else {
      jQuery('.story-title-meta').removeClass("fixed");
    }
  }, {
    offset: -height
  });


  var impactList = jQuery('.section-break').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.categories').addClass("bottom");
    } else {
      jQuery('.categories').removeClass("bottom");
    }
  }, {
    offset: '100%'
  });

  var controller = new ScrollMagic.Controller();
  var controller2 = new ScrollMagic.Controller();


  jQuery('.button.play').click(function(e) { 
    var pageId = jQuery(this).attr('rel');
     setTimeout(function() {
        jQuery('.story-preview-video').fadeOut();
          setTimeout(function() {
            jQuery('.story-preview-video video.jQuery-background-video').pause();
          }, 1);

     }, 500);

    var baseURL = site.root + '/wp-json/wp/v2/story/' + pageId;
    fetch(baseURL, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    }).then(function(response){
      return response.json();
    }).then(function(post){
     jQuery('body').addClass('video');
     jQuery('.story-header-wrapper').append('<div class="story-video"><div class="story-video-wrapper"><div class="story-video-wrapper-detail"><h2><span>Now Playing:</span> ' + post.title.rendered + '</h2><button class="close button button-close">x</button><div class="story-video-wrapper-inner"><div class="video_holder"><iframe src="https://player.vimeo.com/video/' + post.acf.video_url + '?api=1" width="640" height="360" frameborder="0" allowfullscreen="allowfullscreen" id="video" data-ready="true"></iframe></h2></div></div></div><div class="story-video-links"><button class="story-video-link-related category-open arrow-link"> Related Stories</button><button class="story-video-link-more arrow-link"> More about ' + post.title.rendered + '</button></div></div></div>');
     

     jQuery([document.documentElement, document.body]).animate({
      scrollTop: jQuery('.story-header-wrapper').offset().top
    }, 350);
          setTimeout(function() {


            jQuery('.button-close').focus();

          }, 5);


     player = new Vimeo.Player(jQuery('.story-video iframe')[0]);


     player.on('ended', function() {

      jQuery('.video_thumbnail').removeClass('playing');
      jQuery('.story-video-categories').addClass('ended');
      loadRelated();
    });


     jQuery('.category-open').on('keypress click', function(e){
      jQuery('.video_thumbnail').toggleClass('playing');
      jQuery('.story-video-categories').toggleClass('ended');
       player.pause();
       loadRelated();
       jQuery('.story-video-categories-video').focus();


       jQuery('.category-close').on('keypress click', function(e){
        jQuery('.story-video-categories').remove();

       });


     });



     jQuery('.button-close').click(function(e) { 
      closeVideo();
    });
     jQuery('.story-video-link-more').on('keypress click', function(e){
      closeVideo();
           jQuery([document.documentElement, document.body]).animate({
            scrollTop: jQuery('#story-content').offset().top
          }, 650);
     
    });



     
      function loadRelatedDesk() {
           var storiestypes = post.storiestypes + '';
           var storiestypesArr = storiestypes.split(',');
           
           const storiestype = storiestypesArr;
          jQuery('.story-video-wrapper-inner').prepend('<div class="story-video-categories-videos" tabIndex="0"></div>');

           storiestype.forEach(function(storytype){

            var basicURL = site.root + '/wp-json/wp/v2/story?storiestypes=';
            var fullURL = basicURL + storytype + '&_embed&per_page=2&exclude=' + pageId;;
            fetch(fullURL, {
              method: 'GET',
              headers:{
                'Content-Type': 'application/json',
                'accept': 'application/json',
              }
            }).then(function(response){
              return response.json();
            }).then(function(post){
             jQuery.each(post, function(i, item) {
              var title = post[i].title.rendered
              var link = post[i].link
              var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url

              jQuery('.story-video-categories-videos').append('<div class="story-video-categories-video"><div class="story-video-categories-video-art" style="background-image:url('+ imageU +')"></div><a href="'+ link +'">' + title + '</a></div>');



            });



           });


      });
    };


      function loadRelated() {
           var storiestypes = post.storiestypes + '';
           var storiestypesArr = storiestypes.split(',');
           
           const storiestype = storiestypesArr;

          if (jQuery(window).width() < tabletBreakpoint) {

            jQuery('.story-header').prepend('<div class="story-video-categories story-video-categories-mobile"><button class="close button button-close category-close">x</button><div class="story-video-categories-videos" tabIndex="0"></div></div>');
          }
              if (jQuery(window).width() > tabletBreakpoint) {

          jQuery('.story-video-wrapper-inner').prepend('<div class="story-video-categories"><button class="close button button-close category-close">Close Related Stories</button><div class="story-video-categories-videos"></div></div>');
              }

           storiestype.forEach(function(storytype){

            var basicURL = site.root + '/wp-json/wp/v2/story?storiestypes=';
            var fullURL = basicURL + storytype + '&_embed&per_page=2&exclude=' + pageId;;
            fetch(fullURL, {
              method: 'GET',
              headers:{
                'Content-Type': 'application/json',
                'accept': 'application/json',
              }
            }).then(function(response){
              return response.json();
            }).then(function(post){
             jQuery.each(post, function(i, item) {
              var title = post[i].title.rendered
              var link = post[i].link
              var imageU = post[i]._embedded['wp:featuredmedia'][0].source_url
              if (jQuery(window).width() < tabletBreakpoint) {

                  jQuery('.story-video-categories-videos').append('<div class="story-video-categories-video"><div class="story-video-categories-video-art" style="background-image:url('+ imageU +')"></div><a href="'+ link +'">' + title + '</a></div>');
              }
              if (jQuery(window).width() > tabletBreakpoint) {

                jQuery('.story-video-categories-videos').append('<div class="story-video-categories-video"><div class="story-video-categories-video-art" style="background-image:url('+ imageU +')"></div><a href="'+ link +'">' + title + '</a></div>');
              }




            });



           });


      });
    };
     
   });    

  });
  jQuery('.story-play-link').click(function(e) { 
    loadVideo();
  });

}

var vHeight = window.innerHeight;
var vHeight300 = window.innerHeight - 300;
var vHeight100 = vHeight - 100;
var vHeightHalf = window.innerHeight / 2;
var vHeightthree = window.innerHeight / 3;
var vHeightfour = window.innerHeight / 4;
var vHeightfive = window.innerHeight / 5;




// jQuery('.award-module-images').slick({
//   slidesToShow: 4,
//   slidesToScroll: 4,
//   centerMode:true,
//   speed: 500,
//   dots: false,
//   fade: false,
//   nextArrow: '.slide-navigation-external-next',
//   prevArrow: '.slide-navigation-external-prev',

// });

 if (jQuery(window).width() < tabletBreakpoint) {

  $('.award-module-images').slick('unslick');
}
jQuery('.testimonial-module-images').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.testimonial-slider',
  speed: 500,
  dots: false,
  fade: false,
  arrows: false
});
jQuery('.testimonial-module-content-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.testimonial-slider',
  speed: 500,
  dots: false,
  fade: false,
  nextArrow: '.slide-navigation-next',
  prevArrow: '.slide-navigation-prev',
  focusOnSelect: true
});



});

jQuery(window).scroll(function() {
  var vHeight = window.innerHeight;

  jQuery('.nav').removeClass('nav-active')

    Waypoint.refreshAll();

// Scroll Fix if larger than tablet

 if (jQuery(window).width() > tabletBreakpoint) {

  var storyWrapper = jQuery('.story-header').waypoint(function(direction) {
    if (direction === 'down') {
     jQuery('.story-header').removeClass("bottom");
     jQuery('.story-header').addClass("fixed");
    } else {
    jQuery('.story-header').removeClass("bottom");
    jQuery('.story-header').removeClass("fixed");
    }
  });
  var storyWrapperRelease = jQuery('#story-content').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.story-header').addClass("bottom");
    } else {
      jQuery('.story-header').removeClass("bottom");
    }
    }, {
    offset: vHeight
  });

  var newsWrapper = jQuery('.news-wrapper').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.news-preview').addClass("fixed");
    } else {
      jQuery('.news-preview').removeClass("fixed");
    }
  }, {
    offset: 0
  });


  var about = jQuery('.interior-module-content').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.interior-module-content-wrapper-art-wrapper').addClass("fixed");
    } else {
      jQuery('.interior-module-content-wrapper-art-wrapper').removeClass("fixed");
    }
  });


}

  if (jQuery('body').hasClass('post-type-archive')) { 
    var categoriesScroll = jQuery('._wrapper').waypoint(function(direction) {
      if (direction === 'down') {
        jQuery('.category-select').addClass("fixed");
      } else {
        jQuery('.category-select').removeClass("fixed");
      }
    }, {
      offset: '100'
    });

    var categoriesScrollDown = jQuery('.section-break').waypoint(function(direction) {
      if (direction === 'down') {
        jQuery('.category-select').addClass("bottom");
      } else {
        jQuery('.category-select').removeClass("bottom");
      }
    }, {
      offset: '100%'
    });
    }





  var categoryPreviewWrapper = jQuery('#categories').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('#previewWrapper').addClass("opened");
    } else {
      jQuery('#previewWrapper').removeClass("opened");
    }
  }, {
    offset: '100%'
  });

  var veterans = jQuery('#posts').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('#content-frame').addClass("scrollAway");
    } else {
      jQuery('#content-frame').removeClass("scrollAway");
    }
  }, {
    offset: '100%'
  });

  var vHeight = window.innerHeight;
  var vHeightHalf = vHeight/2;

  var about = jQuery('.grid-numbers').waypoint(function(direction) {
    if (direction === 'down') {
      jQuery('.interior-module-content-wrapper-art-wrapper').addClass("bottom");
    } else {
      jQuery('.interior-module-content-wrapper-art-wrapper').removeClass("bottom");
    }
  }, {
    offset: vHeightHalf + 100
  });

});